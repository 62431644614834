




















































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import CompetencesEditor from '@/components/forms/CompetencesEditor.vue'
import { CompetenceCorridorForFrontend, CompetenceCorridorServerObject, MetaProfile } from '@/store/kode/types'
import {
  DELETE_META_PROFILE,
  GET_ALL_META_PROFILES, SAVE_META_PROFILE,
  TRANSFORM_COMPETENCE_CORRIDORS_FOR_BACKEND,
  TRANSFORM_COMPETENCE_CORRIDORS_FOR_FRONTEND
} from '@/store/kode/actions'
import { Action, Getter, Mutation, State } from 'vuex-class'
import { SimpleServerResponse, TableFields } from '@/store/types'
import CompetenceCorridor from '@/components/common/CompetenceCorridor.vue'
import TextCreatorForSubmitSuccess from '@/components/forms/getHeadlineAndInfoForSubmitSuccess'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { MU_SET_META_PROFILES } from '@/store/kode/mutations'
import Competence from '@/components/hszg/Competence.vue'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
@Component({
  components: { LoadingSpinner, Competence, ConfirmationModal, CompetenceCorridor, CompetencesEditor, ClickableIcon }
})
export default class MetaTargetProfiles extends Vue {
  @State('currentScreenWidth') screenWidth: number

  @Action(GET_ALL_META_PROFILES)
  getAllMetaProfiles: () => Promise<Array<MetaProfile>>

  @Action(TRANSFORM_COMPETENCE_CORRIDORS_FOR_FRONTEND)
  transFormCorridorsForFrontend: (toTransform: Array<CompetenceCorridorServerObject>) => Promise<Array<CompetenceCorridorForFrontend>>

  @Action(TRANSFORM_COMPETENCE_CORRIDORS_FOR_BACKEND)
  transFormCorridorsForBackend: (toTransform: Array<CompetenceCorridorForFrontend>) => Promise<Array<CompetenceCorridorServerObject>>

  @Action(SAVE_META_PROFILE)
  saveMetaProfile: (profile: MetaProfile) => Promise<SimpleServerResponse>

  @Action(DELETE_META_PROFILE)
  deleteMetaProfile: (id: string) => Promise<SimpleServerResponse>

  @Getter('metaProfilesGetter') getMetaProfilesFromStore: Array<MetaProfile>

  @Mutation(MU_SET_META_PROFILES)
  public mutateMetaProfiles: (newEntries: Array<MetaProfile>) => void

  private metaProfiles: Array<MetaProfile> = new Array<MetaProfile>()
  private selectedCorridors: Array<CompetenceCorridorForFrontend> = new Array<CompetenceCorridorForFrontend>()
  private selectedMetaProfile: MetaProfile
  private selectedProfileName = ''
  private selectedProfileType = 'JOB_FAMILY'
  private allowToSave = false
  private bookmarkedForDelete: string | null = null

  private editorModalHeadline = ''
  private minCountCorridors = 8
  private maxCountCorridors = 16
  private minDiffCorridor = 2
  private maxDiffCorridor = 4
  private orderBy = 'name'
  private orderByCompetence = 'competence'

  private textCreator: TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>
  private feedbackHeadline = ''
  private responseState = false
  private profilesLoading = true

  get fields (): TableFields[] {
    return [
      { key: 'name', label: this.$t('target-profiles.profile-name').toString(), sortable: true },
      { key: 'type', label: this.$t('target-profiles.type').toString(), sortable: true },
      { key: 'actions', label: this.$t('actions.action').toString() }
    ]
  }

  get isACompetenceIncluded (): boolean {
    return this.getShortCompetence('A')
  }

  get isPCompetenceIncluded (): boolean {
    return this.getShortCompetence('P')
  }

  get isFCompetenceIncluded (): boolean {
    return this.getShortCompetence('F')
  }

  get isSCompetenceIncluded (): boolean {
    return this.getShortCompetence('S')
  }

  private getShortCompetence (letter: string): boolean {
    let found = false
    this.selectedCorridors.forEach(corridor => {
      const shortCompetences: string[] = (this.$i18n.t('all-competences.' + corridor.competence + '.shortCompetences') as unknown) as string[]
      if (shortCompetences.includes(letter)) {
        found = true
      }
    })
    return found
  }

  public showEditorModal (editorModalHeadline: string, profile: MetaProfile): void {
    this.editorModalHeadline = editorModalHeadline
    this.selectedMetaProfile = { ...profile }
    this.selectedProfileName = this.selectedMetaProfile.name
    this.selectedProfileType = this.selectedMetaProfile.type
    this.transFormCorridorsForFrontend(profile.competenceCorridors).then((corridors) => {
      this.selectedCorridors = corridors
      this.isAbleToSave(this.selectedCorridors)
      this.$bvModal.show('edit-meta-target-profile')
    })
  }

  public showDeleteConfirmationModal (profileId: string): void {
    this.bookmarkedForDelete = profileId
    this.$bvModal.show('confirmation-modal-delete-profile')
  }

  public profileMetaDataChanged (metaData: MetaProfile): void {
    this.selectedProfileName = metaData.name ? metaData.name : ''
    this.selectedProfileType = metaData.type ? metaData.type : 'JOB_FAMILY'
    this.isAbleToSave(this.selectedCorridors)
  }

  public reloadProfilesFromStore (): void {
    this.metaProfiles = this.getMetaProfilesFromStore
    this.$bvModal.hide('edit-meta-target-profile')
  }

  public isAbleToSave (modifiedCorridors: Array<CompetenceCorridorForFrontend>): void {
    this.selectedCorridors = modifiedCorridors
    const allBaseCompetencesIncluded = this.isACompetenceIncluded && this.isFCompetenceIncluded && this.isPCompetenceIncluded && this.isSCompetenceIncluded
    const reachedRequiredCount = this.selectedCorridors.length >= this.minCountCorridors && this.selectedCorridors.length <= this.maxCountCorridors
    const profileNameExistent = this.selectedProfileName !== ''
    const profileTypeExistent = this.selectedProfileType !== ''
    this.allowToSave = allBaseCompetencesIncluded && reachedRequiredCount && profileNameExistent && profileTypeExistent
  }

  public deleteProfile (): void {
    if (this.bookmarkedForDelete !== null) {
      this.$root.$emit('load')
      this.deleteMetaProfile(this.bookmarkedForDelete).then((data) => {
        this.textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(data, 'target-profiles.delete.')
        this.responseState = true
        const index = this.findIndexInMetaProfileArray(this.bookmarkedForDelete as string)
        if (index !== -1) {
          this.metaProfiles.splice(index, 1)
          this.mutateMetaProfiles(this.metaProfiles)
        }
        this.$root.$emit('bv::refresh::table', 'meta-target-profile-table')
      }).catch(error => {
        this.textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(error, 'target-profiles.delete.')
        this.responseState = false
      }).finally(() => {
        this.$root.$emit('end-load')
        this.feedbackHeadline = this.textCreator.getHeadline()
        this.$root.$emit('alert', this.feedbackHeadline, '', !this.responseState)
        this.bookmarkedForDelete = null
      })
    }
  }

  public updateProfile (event: Event): void {
    event.preventDefault()
    this.$root.$emit('load')
    this.transFormCorridorsForBackend(this.selectedCorridors).then(data => {
      this.selectedMetaProfile.competenceCorridors = data
      this.selectedMetaProfile.name = this.selectedProfileName
      this.selectedMetaProfile.type = this.selectedProfileType

      this.$bvModal.hide('edit-meta-target-profile')
      this.saveMetaProfile(this.selectedMetaProfile).then((data) => {
        this.textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(data, 'target-profiles.save.')
        this.responseState = true
        this.selectedMetaProfile.id = data.content
        const index = this.findIndexInMetaProfileArray(this.selectedMetaProfile.id as string)
        if (index === -1) {
          this.metaProfiles.push(this.selectedMetaProfile)
        } else {
          this.metaProfiles[index] = this.selectedMetaProfile
        }
        this.mutateMetaProfiles(this.metaProfiles)
        this.$root.$emit('bv::refresh::table', 'meta-target-profile-table')
      }).catch(error => {
        this.textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(error, 'target-profiles.save.')
        this.responseState = false
        this.reloadProfilesFromStore()
      }).finally(() => {
        this.$bvModal.hide('edit-meta-target-profile')
        this.feedbackHeadline = this.textCreator.getHeadline()
        this.$root.$emit('end-load')
        this.$root.$emit('alert', this.feedbackHeadline, '', !this.responseState)
      })
    })
  }

  private getProfiles (): Promise<Array<MetaProfile>> {
    this.profilesLoading = true
    return this.getAllMetaProfiles().then(data => {
      this.metaProfiles = data
      return data
    }).catch(error => {
      this.responseState = false
      this.feedbackHeadline = this.$i18n.t('target-profiles.could-not-load').toString()
      this.$root.$emit('alert', this.feedbackHeadline, '', true)
      throw error
    }) as Promise<Array<MetaProfile>>
  }

  private findIndexInMetaProfileArray (profileId: string): number {
    return this.metaProfiles.findIndex(profile => {
      return profile.id === profileId
    })
  }

  mounted (): void {
    this.getProfiles().finally(() => { this.profilesLoading = false })
  }
}
